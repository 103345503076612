.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}

.lobby {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}


.AskPermission{
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20vh;
  width: 100%;
  overflow: hidden;
}

.AskPermission .reload{
  margin-top: 50px;
}


.AskPermission .Title{
  max-width: 500px;
  font-size: 30px;
  font-weight: 900;
  padding: 10px;
}


.AskPermission .Illustration{
 width: 100vmin;
}

.AskPermission .link{
  padding-top: 50px;
  color:black;
  text-decoration: none;
  opacity: .3;
}

.AskPermission .link:hover{
  opacity: 1;
}

.video{
  transform: rotateY(180deg);
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  max-width: 1024px;
  min-height: 100%;
  object-fit: cover;
}

.video-width-radius{
  transform: rotateY(180deg);
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 17px;
  box-shadow: rgba(23, 23, 23, .5) 0px 0px 3px
}

.video-without-radius{
  transform: rotateY(180deg);
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: .1px;
 
}


.guest-feed{
  height: 100vh;
  width: 100vw;
  text-align: center;
  position: relative;
  border: 5px solid black;
}

.guest-feed .audio-only{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  top:0;
  background-image: url(https://d3lq8p6p6r1qxf.cloudfront.net/5d2718082d144ae6a68fba72fe808fd3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  filter: blur(10px);
  overflow: hidden;
}

.my-feed{
  position: fixed;
  z-index: 999999;
  top: 60px;
  left: 40px;
  height: 100px;
  width: 100px;
  text-align: center;
  border-radius: 17px;
  transition: .5s all;
}

 .alone{
  height: 100vh;
  width: 100vw;
  top:0;
  left: 0;
  z-index: -1
}

button {
  border: none;
  font: inherit;
  padding: 10px 20px;
  color: white;
  background-color: black;
  border-radius: 15px;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

button:active {
  background-color: white;
  color:black
}

.available{
  position: fixed;
  z-index: 9999;
  bottom: 10px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width:350px;
  height: 70px;
  background-color: #f44336;
  border-radius: 35px;
  box-shadow: rgba(23, 23, 23, .9) 0px 0px 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 5px solid rgba(255,255,255,.05)
}

.available:active{
  transform: scale(.98);
}

.available h1 {
  color: #ffffff;
  font-size: 18px;
 }


.home__icon{
 margin-top: 25px;
}
.available .home__icon g {
  fill: #ffffff;
 }

 .available .home__icon path {
  stroke: #e5e5e5;
  stroke-width: 10px;
 }

 .masonry { 
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px; /* Adjustment for the gutter */
  width: 100%;
}

.masonry-brick {
  flex: auto;
  height: 250px;
  min-width: 150px;
  margin: 0 8px 8px 0; /* Some gutter */
}

.masonry-brick:nth-child(4n+1) {
  width: 250px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+2) {
  width: 325px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+3) {
  width: 180px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+4) {
  width: 380px;
}